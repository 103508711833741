import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import { Message } from "view-design";
// 全局样式 iview 定制化暂未开始
// import "view-design/dist/styles/iview.css";
import "./my-theme/index.less";
// iconfont
import "./assets/iconfont/iconfont.css";
// @ts-ignore
import VueTour from "vue-tour";

import * as echarts from "echarts"; // 挂载到Vue实例上面

import videojs from "video.js";
import "video.js/dist/video-js.css";

moment.locale("zh-cn");

require("vue-tour/dist/vue-tour.css");

Vue.prototype.$echarts = echarts;
Vue.prototype.$video = videojs;

Vue.prototype.$Message = Message;
Vue.use(VueTour);
Vue.config.productionTip = process.env.NODE_ENV !== "production";
const history = localStorage.getItem("tour") || "{}";
localStorage.setItem("tour", JSON.stringify(Object.assign(require("./assets/tourGuid.json"), JSON.parse(history))));
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
