import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { Component } from "vue-property-decorator";
import Cookie from "js-cookie";

Component.registerHooks([
	"beforeRouteEnter",
	"beforeRouteUpdate",
	"beforeRouteLeave"
]);
Vue.use(VueRouter);

const routes:RouteConfig[] = [
	{
		path: "/",
		name: "root",
		redirect: "/home2/"
	},
	{
		path: "/home/",
		name: "home",
		component: () => import("../views/Home.vue")
	},
	{
		path: "/home2/",
		name: "home2",
		component: () => import("../views/Home2.vue")
	},
	{
		path: "/login/",
		name: "login",
		component: () => import("../views/login/login.vue")
	},
	{
		path: "/medicalData",
		name: "medicalRoot",
		component: () => import("../views/medicalData/index.vue"),
		children: [
			{
				path: "outpatient/",
				name: "outpatientData",
				component: () => import("../views/medicalData/outpatient.vue")
			},
			{
				path: "hospital/",
				name: "hospitalData",
				component: () => import("../views/medicalData/hospital.vue")
			},
			{
				path: "device/",
				name: "hospitalDevice",
				component: () => import("../views/medicalData/device.vue")
			},
			{
				path: "epidemic/",
				name: "epidemic",
				component: () => import("../views/medicalData/epidemic.vue")
			}
		]
	},
	{
		path: "/space/",
		name: "space",
		component: () => import("../views/space/spaceRoot.vue"),
		children: [
			{
				path: "main/",
				name: "spaceMain",
				component: () => import("../views/space/spaceMain.vue")
			}
		]
	},
	{
		path: "/smt/",
		name: "smt",
		component: () => import("../views/smt/smtRoot.vue"),
		children: [
			{
				path: "main/",
				name: "smtMain",
				component: () => import("../views/smt/smtMain.vue")
			},
			{
				path: "mvideo/",
				name: "mvideo",
				component: () => import("../views/smt/smtVideo.vue")
			}
		]
	},
	{
		path: "/device/",
		name: "device",
		component: () => import("../views/device/deviceRoot.vue"),
		children: [
			{
				path: "main/",
				name: "deviceMain",
				component: () => import("../views/device/deviceMain.vue")
			},
			{
				path: "elevator/",
				name: "elevator",
				component: () => import("../views/device/elevator.vue")
			},
			{
				path: "main2/",
				name: "elevatormain2",
				component: () => import("../views/device/main2.vue")
			}
		]
	},
	{
		path: "/repair/",
		name: "repair",
		component: () => import("../views/repair/repairRoot.vue"),
		children: [
			{
				path: "repair/",
				name: "repairTable",
				component: () => import("../views/repair/repair.vue")
			},
			{
				path: "maintenance/",
				name: "maintenanceTable",
				component: () => import("../views/repair/maintenance.vue")
			},
			{
				path: "maintenancePlan/",
				name: "maintenancePlan",
				component: () => import("../views/repair/maintenancePlan.vue")
			},
			{
				path: "requestAdd/",
				name: "requestAdd",
				component: () => import("../views/repair/addRequest.vue")
			},
			{
				path: "operation/:id/",
				name: "operation",
				component: () => import("../views/repair/operation.vue")
			},
			{
				path: "detail/:id/",
				name: "detail",
				component: () => import("../views/repair/operation.vue")
			}
		]
	},
	{
		name: "energy",
		path: "/energy/",
		component: () => import("../views/energy/energyRoot.vue"),
		children: [
			{
				name: "energyMain",
				path: "main/",
				component: () => import("../views/energy/energyCount.vue")
			},
			// 用水分析
			{
				name: "energyWater",
				path: "water/",
				component: () => import("../views/energy/energyWater.vue")
			},
			// 用电分析
			{
				name: "energyEle",
				path: "ele/",
				component: () => import("../views/energy/energyEle.vue")
			}
		]
	},
	// 气体监测
	{
		name: "gas",
		path: "/gas/gasMain/",
		component: () => import("../views/gas/gasMain.vue")
	},
    // 气体监测
	{
		name: "car",
		path: "/car/index/",
		component: () => import("../views/car/index.vue")
	}
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.NODE_ENV === "production" ? "/static/UnityWeb/desktop/dist/" : "/",
	routes
});

// 全局前置守卫
// router.beforeEach((to, from, next) => {
// 	// 处理登录
// 	console.log(Cookie.get("csrftoken"), "                  sessionid2.0:" + Cookie.get("sessionid"));
// 	let cfstoken = Cookie.get("csrftoken");
// 	// 不是跳转登录页面的时候需要验证用户是否已经登录。
// 	if (to.path != "/login") {
// 		// 跳转页面时，验证页面中是否有登录信息
// 		if (cfstoken) {
// 			// 存在登录信息
// 			next();
// 		} else {
// 			// 不存在登录信息，跳转登录页
// 			next({
// 				path: "/login"
// 			});
// 		}
// 	} else {
// 		next();
// 	}
// });

export default router;
