
	import { Component, Vue, Watch, Prop } from "vue-property-decorator";
	import request from "@/request";
	// @ts-ignore
	import Bus from "./Bus";
	@Component({})
		export default class floorDom extends Vue {
        @Prop() readonly routerType:any;
        floordata:any = [];
        activeId: number = 0;
        chooseLi (item) {
            this.activeId = item.id;
            // 模型展示页面
            Bus.emit("changeFloorId", item.id);
            // 用于房间页面 根据楼层过滤房间
            this.$store.commit("changefloorId", item.id);
        }
        getfacility () {
            request("/prj/facility/?format=json").then(({ data }) => {
                if (data.length > 0) {
			        this.getfloor(data[0].id);
                }
            });
        }
        getfloor (id) {
            request(`/space/floor/?format=json&facility=${ id }&pagesize=999`).then(({ data }) => {
                this.floordata = data;
            });
        }
        mounted () {
            this.getfacility();
            Bus.on("changeKongjianFloorId", (id) => {
                this.activeId = id;
                // 用于房间页面 根据楼层过滤房间
                this.$store.commit("changefloorId", id);
            });
		}
	}
