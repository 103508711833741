
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Icon } from "view-design";
@Component({
  components: {
    Icon,
  },
})
export default class Drag extends Vue {
  @Prop({ default: 0, type: Number }) readonly width!: number;
  @Prop({ default: 0, type: Number }) readonly height!: number;
  @Prop({ default: 0, type: Number }) readonly left!: number;
  @Prop({ default: 0, type: Number }) readonly top!: number;
  @Prop({ default: false, type: Boolean }) readonly showClose!: boolean;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly icon!: string;
  @Prop(Function) readonly exportFunction!: Function;
  currentLeft: number = this.left;
  currentTop: number = this.top;
  private MainLink: HTMLLinkElement = document.createElement("link");

  startDrag(): void {
    const mouseMove = (ev: MouseEvent) => {
      this.currentLeft += ev.movementX;
      this.currentTop += ev.movementY;
    };
    document.addEventListener("mousemove", mouseMove);
    document.addEventListener(
      "mouseup",
      (ev) => {
        document.removeEventListener("mousemove", mouseMove);
      },
      { once: true }
    );
  }

  renderCss() {
    less
      .render(lessFiles.drag, {
        javascriptEnabled: true,
        modifyVars: {
          "@baseColor": this.$store.state.baseColor,
          path: "/static/UnityWeb/desktop/",
        },
        paths: ["/static/UnityWeb/desktop/"],
      })
      .then((data) => {
        this.MainLink && this.MainLink.remove();
        const href: string = URL.createObjectURL(new Blob([data.css]));
        const link = document.createElement("link");
        link.href = href;
        link.rel = "stylesheet";
        document.head.appendChild(link);
        this.MainLink = link;
      });
  }

  mounted(): void {
    this.renderCss();
    console.log("1111");
    console.log(this.left);
    this.currentTop = this.top;
    this.currentLeft = this.left;
  }

  @Watch("$store.state.baseColor")
  onColorChange() {
    this.renderCss();
  }

  beforeDestroy() {
    this.MainLink && this.MainLink.remove();
  }
}
