import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		showType: "device",
		floorValue: "", // 维修单-楼层筛选
		colorType: "category",
		showWalls: true,
		liveStream: "",
		showCamera: false,
		device: "",
		showSystem: "",
		flow: false,
		baseColor: localStorage.getItem("baseColor") || "#4261ED",
		shebeiModal: 0, // 点击bimface模型中icon
		shebeiContent: null, // 点击bimface模型中icon,储存设备信息
		floorId: "", // 楼层
		isDrawBack: false, // 页面的左右收拉
		unityEnd: 0, // unity 模型加载完成
		relatetype: "", // 维修单 table 点击设备，房间标签 对列表进行筛选
		relatedid: "", // 维修单-房间，设备id筛选
		addRepair: {
			floorid: "",
			roomid: "",
			add: 0
		} // 模型房间标签右键触发新增工单
	},
	mutations: {
		unityEnd (state, payload) {
			// unity 模型
			state.unityEnd++;
		},
		changeDraw (state, payload) {
			state.isDrawBack = payload;
		},
		changefloorId (state, payload) {
			state.floorId = payload;
		},
		clickShebei (state, payload) {
			state.shebeiContent = payload;
			state.shebeiModal++;
		},
		changeViewByCode (state, payload) {
			state.showType = payload.type;
			state.floorValue = payload.id;
		},
		fChangeFloor (state, payload) {
			state.showType = payload.type;
			state.floorValue = payload.id;
		},
		changeColorType (state, payload) {
			state.colorType = payload;
		},
		changeWall (state, payload) {
			state.showWalls = payload;
		},
		updateLiveStream (state, payload) {
			state.liveStream = payload.split(",")[ 1 ];
		},
		findFloorAndCamera (state, payload) {
			state.floorValue = payload[ 0 ];
		},
		updateCameraStatus (state, payload) {
			state.showCamera = payload;
		},
		selectDevice (state, payload) {
			state.device = payload;
		},
		changeModule (state, payload) {
			state.showType = payload.type;
			state.floorValue = payload.floor;
			// console.log(state.floorValue);
		},
		changeSystem (state, payload) {
			state.showSystem = payload;
		},
		changeFlow (state, payload) {
			state.flow = payload;
		},
		findRoom (state, payload) {
			state.floorValue = payload.floor;
		},
		ShowEviromentofThewall (state, payload) {
		},
		OpenSpacepointinf (state, payload) {
		},
		changeBoolean (state, payload) {
		},
		changeBaseColor (state, payload) {
			state.baseColor = payload;
			localStorage.setItem("baseColor", payload);
		},
		searchTableRoom (state, payload) {
			state.relatetype = payload.type;
			state.relatedid = payload.id;
		},
		triggerAdd (state, payload) {
			state.addRepair.floorid = payload.floorid;
			state.addRepair.roomid = payload.roomid;
			state.addRepair.add++;
		}
	},
	actions: {},
	modules: {}
});
