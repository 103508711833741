
	import { Component, Vue, Watch } from "vue-property-decorator";
	import { DatePicker } from "view-design";
	import moment from "moment";
	import request from "@/request";
	@Component({
        components:{
            DatePicker
        }
    })
		export default class myMessage extends Vue {
        messageData:any=[];
        yjNum = 0;
        wxNum = 0;
        wbNum = 0;
        mytext=[
            {
                name: "设备报警",
                number: 0,
                type: 3
            },
            {
                name: "维修",
                number: 0,
                type: 1
            },
            {
                name: "维保",
                number: 0,
                type: 2
            }
        ]
        page = 1;
        mtype:any = "";
        scrollTop:any = 0;
        scrollHeight:any = 0;
        offsetHeight:any = 0;
        count = 0;
        mytime:any = [new Date(Date.now() - 30 * 24 * 3600 * 1000), new Date()];
        intoPage (item) {
            this.$emit("close", false);
            request({
                url: `/user/message/${ item.id }/`,
                data: {
                    status: 2
                },
                method: "PATCH"
            });
            this.$router.push({
                path: `/repair/operation/${ item.event }/`
            });
        }
        changeType (type) {
            this.mtype = this.mtype === type ? "" : type;
            this.page = 1;
			this.getPage();
            this.getdata();
        }
		getdata () {
            let timeTemp = [];
            if (this.mytime.length === 2 && this.mytime[0]) {
                timeTemp = [`${ moment(this.mytime[0]).format("yyyy-MM-DD") } 00:00:00`, `${ moment(this.mytime[1]).format("yyyy-MM-DD") } 23:59:59`];
            }
            request(`/user/message/?mtype=3&time__range=${ timeTemp }`).then(({ data }) => {
                this.mytext[0].number = data.count;
            });
            request(`/user/message/?mtype=1&time__range=${ timeTemp }`).then(({ data }) => {
                this.mytext[1].number = data.count;
            });
            request(`/user/message/?mtype=2&time__range=${ timeTemp }`).then(({ data }) => {
                this.mytext[2].number = data.count;
            });
		}
        getPage () {
            let timeTemp = [];
            if (this.mytime.length === 2 && this.mytime[0]) {
                timeTemp = [`${ moment(this.mytime[0]).format("yyyy-MM-DD") } 00:00:00`, `${ moment(this.mytime[1]).format("yyyy-MM-DD") } 23:59:59`];
            }
            request(`/user/message/?mtype=${ this.mtype }&page=${ this.page }&time__range=${ timeTemp }`).then(({ data }) => {
                data.results.forEach(item => {
                    item.time = item.time.replace("T", " ");
                });
                this.messageData = this.page === 1 ? data.results : this.messageData.concat(data.results); ;
                this.count = data.count;
            });
        }
        bindScroll () {
            this.scrollTop = document.getElementById("mymessage_Dom").scrollTop;
            this.scrollHeight = document.getElementById("mymessage_Dom").scrollHeight;
            this.offsetHeight = document.getElementById("mymessage_Dom").offsetHeight;
        }
		mounted () {
			this.getdata();
			this.getPage();
            let _this = this;
            this.$nextTick(() => {
                document.getElementById("mymessage_Dom").onscroll = function () {
                    _this.bindScroll();
                };
            });
		}
        @Watch("scrollTop")
        onscrollTopchange () {
            if (this.scrollHeight <= (this.offsetHeight + this.scrollTop)) {
                //  console.log("拉取更多数据", this.page * 20, this.count);
                if ((this.page * 20) < this.count) {
                    this.page++;
                    this.getPage();
                }
            }
        }
	}
