
    // @ts-nocheck
	import { Button, ColorPicker, TabPane, Tabs, Spin } from "view-design";
	import { Component, Vue, Watch } from "vue-property-decorator";
	import moment from "moment";
	import request from "@/request";
	import drag from "@/components/drag/drag.vue";
	import axios, { AxiosPromise } from "axios";
	import less from "less";
	import { CreateElement, VNode } from "vue";
	import { Route } from "vue-router";
	import bimfaceMain from "@/components/bimfaceModel.vue";
	import floorDom from "@/components/kongjian.vue";
	import myMessage from "@/components/message.vue";
	import meaus from "@/components/common/meau.ts";
	import Cookie from "js-cookie";

	// const router = useRouter();
	// console.log("路由", floorDom, "dsadsa");
	const imgMap = {
		info: require("./assets/E.png"),
		repair: require("./assets/C.png"),
		source: require("./assets/B.png"),
		monitor: require("./assets/A.png"),
		file: require("./assets/D.png"),
		device: require("./assets/room/device.png"),
		video: require("./assets/room/video.png"),
		roombianhao: require("./assets/room/icon_fangjianhao.png"),
		roomtype: require("./assets/room/icon_fangjianleixing.png"),
		roomName: require("./assets/room/icon_fangjianming.png"),
		jzmj: require("./assets/room/icon_jianzhumianji.png"),
		bm: require("./assets/room/icon_shiyongbumen.png"),
		sybm: require("./assets/room/icon_shiyongbumen.png"),
		symj: require("./assets/room/icon_shiyongmianji.png")
	};
	const CancelToken = axios.CancelToken;
	const noModule = false;

    interface menu {
    name: string;
    icon?: string;
    children?: subMenu[];
    }

    interface subMenu {
    name: string;
    icon: string;
    url: string;
    }

    interface deviceInfoTable {
    name: string;
    value: any;
    range?: any;
    icon?: string;
    }

	@Component({
        components: {
            Button,
            drag,
            Tabs,
            TabPane,
            ColorPicker,
            Spin,
            bimfaceMain,
            floorDom,
            myMessage
        }
    })
		export default class App extends Vue {
            showSecond = false;
            showMessage = false;
            isappDrawBack:boolean = true;
            public time: string = "";
            public unread: number = 0;
            public myindex1: number = -1;
            public myindex2: number = -1;
            public myroom: string = "";
            public mydevice: string = "";
            public closePoStatus: boolean = false;
            public closeManStatus: boolean = false;
            public closeCeStatus: boolean = false;
            public closexhStatus: boolean = false;
            public showSearch: number = -1;
            public showloading: boolean = false;
            public searchroom: any[] = [];
            public searchdevice: any[] = [];
            public isRoominfo: boolean = true;
            public isLogin: boolean = true;
            public menuActiveId: any = 0;
            public menuHoverIndex: number = 0;
            public topLevel: menu[] = [];
            public secondActive: string = "";
            public secondLevel: subMenu[] = [];
            public attachInfo: { name: string; value: any }[] = [];
            public roomMessage: any = {
                repairlist: [],
                video: "",
                filelist: [],
                devicelist: []
            };
            public showAttach: boolean = false;
            // public visibility: 0 | string = "9999px";
            public visibility: string = "0";
            public src: string = "";
            public showDevice: boolean = false;
            public deviceInfo: {
                [base: string]: any[] | string;
                moduleSrc: string;
                repair: any[];
            } = {
                base: [],
                sensor: [],
                file: [],
                moduleSrc: "",
                downstream: [],
                upstream: [],
                repair: []
            };
            inUse: string[] = [];
            public readonly noModule: boolean =
                process.env.NODE_ENV === "production" ? false : noModule;
            baseColor: string = this.$store.state.baseColor;
            private cancelToken?: any;
            private locks: (() => void)[] = [];
            private link: HTMLLinkElement = document.createElement("link");
            private MainLink: HTMLLinkElement = document.createElement("link");
            private workLine: Promise<any>[] = [];
            private firstLoad?: Promise<any>;
            private status = {
                ReShader: false
            };
            showBtnAll = false;
            unityBtns = [
                {
                    name: "主视角",
                    value: "3"
                },
                {
                    name: "俯视",
                    value: "2"
                },
                {
                    name: "操场",
                    value: "0"
                },
                {
                    name: "正大门",
                    value: "1"
                }
            ];
            unityBtnactive = "3";
            weather = {
                temp: 20,
                img: require("@/assets/icon/weather/qingtian.svg")
            }
            goOut () {
                request("/logout/").then(res => {
                    this.isLogin = false;
                    this.$router.push("/login");
                });
            }
            clickMenu (i: menu, index: number, type:any) {
                console.log(i.isnewtab);
                if (!i || (i.url === void 0 || i.url === "")) {
                    return;
                }
                if (type === 2) {
                    this.menuActiveId = this.topLevel[this.menuHoverIndex].id;
                    this.secondActive = i.name;
                }
                if (i.name === "系统配置") {
                    window.open(`${ window.location.origin }/config/#/`);
                } else {
                    if (i.isnewtab) {
                        window.open(`${ window.location.origin }/#${ i.url }`);
                    } else {
                        this.$router.push(i.url);
                    }
                }
            }
            public handleMenuClick (i: menu, index: number) {
                this.secondLevel = [];
                if (i.name === "系统配置") {
                    return;
                }
                this.menuHoverIndex = index;
                if (!i.hasOwnProperty("children")) {
                    this.getChildMeaus(i.id).then(data => {
                        i.children = data;
                        this.secondLevel = i.children;
                    });
                }
                if (i.children && i.children.length > 0) {
                    this.inUse = i.children.map((t) => t.href);
                    this.secondLevel = i.children;
                } else {
                    this.inUse = [];
                }
            }

            // 展示bimface
            showBimface: boolean = false;
            routerType: any = null;
            operation (operation: string) {
                let payload: undefined | boolean;
                if (operation !== "ResetView") {
                // @ts-ignore
                payload = this.status[operation] = !this.status[operation];
                }
                this.firstLoad!.then(() => {
                // @ts-ignore
                document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage(
                    "Canvas",
                    operation,
                    payload ? payload.toString() : ""
                    );
                });
            }

            public getMessage (): void {
                request("/user/message/?user=&status=1&pagesize=1").then(({ data }) => {
                this.unread = data.count;
                setTimeout(() => {
                    this.getMessage();
                }, 15000);
                });
            }
            changeRoom (item: any, index: number) {
                this.myindex1 = index;
                if (!item.room_guid) {
                this.$Message.error("当前房间没有模型录入");
                return;
                }
                // console.log(item);
                // @ts-ignore
                this.$store.commit("findRoom", {
                floor: item.floorid,
                id: item.room_guid,
                path: [item.floorfacilityid, item.floorid, item.id]
                });
            }
            toFloordevice (item: any, index: number) {
                // console.log(item);
                this.myindex2 = index;
                this.$store.commit("changeViewByCode", { id: item.dbid.floor_id, type: "device" });
                // @ts-ignore
                document
                .getElementById("frame")
                .contentWindow.gameInstance.SendMessage(
                    "Canvas",
                    "CameraMove",
                    `${ item.dbid.floor_id }/${ item.guid }`
                );
            }
            restModule (url: string) {
                this.locks.forEach((t) => t());
                this.locks = [];
                // this.visibility = "9999px";
                // @ts-ignore
                this.firstLoad.then(() => {
                // @ts-ignore
                document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage(
                    "Canvas",
                    "ChangeScenesMassage",
                    String(url)
                    );
                });
                this.status = {
                ReShader: false
                };
                this.firstLoad = new Promise((resolve) => {
                window.end = () => {
                    this.visibility = 0;
                    // @ts-ignore
                    resolve();
                };
                });
            }

            poQie () {
                this.firstLoad!.then(() => {
                // @ts-ignore

                if (!this.closePoStatus) {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "ReShader", "true");
                    this.closePoStatus = true;
                    this.$Message.success("打开剖切!");
                } else {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "ReShader", "false");
                    this.closePoStatus = false;
                    this.$Message.success("关闭剖切!");
                }
                });
            }

            manYou () {
                this.firstLoad!.then(() => {
                // @ts-ignore

                if (!this.closeManStatus) {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "Roma", "true");
                    this.closeManStatus = true;
                    this.$Message.success("开始漫游!");
                } else {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "Roma", "false");
                    this.closeManStatus = false;
                    this.$Message.success("结束漫游!");
                }
                });
            }

            xuhua () {
                this.firstLoad!.then(() => {
                // @ts-ignore
                if (!this.closexhStatus) {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "ViewSingle", "true/''");
                    this.closexhStatus = true;
                    this.$Message.success("开启虚化!");
                } else {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "ViewSingle", "false/''");
                    this.closexhStatus = false;
                    this.$Message.success("关闭虚化!");
                }
                });
            }

            ceLiang () {
                this.firstLoad!.then(() => {
                // @ts-ignore

                if (!this.closeCeStatus) {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "MeasuringTool", "true");
                    this.closeCeStatus = true;
                    this.$Message.success("打开测量!");
                } else {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "MeasuringTool", "false");
                    this.closeCeStatus = false;
                    this.$Message.success("关闭测量!");
                }
                });
            }

            ChangeBaseColor (color: string) {
                document.getElementById("root").style.setProperty("--themeColor", color);
                this.$store.commit("changeBaseColor", color);
            }

            renderCss () {
                less.render(lessFiles.global, {
                    javascriptEnabled: true,
                    modifyVars: {
                    "@baseColor": this.$store.state.baseColor,
                    path: "/static/UnityWeb/desktop/"
                    },
                    paths: ["/static/UnityWeb/desktop/"]
                })
                .then((data) => {
                    this.MainLink && this.MainLink.remove();
                    const href: string = URL.createObjectURL(new Blob([data.css]));
                    const link = document.createElement("link");
                    link.href = href;
                    link.rel = "stylesheet";
                    document.head.appendChild(link);
                    this.MainLink = link;
                });
                less.render(lessFiles.Home, {
                    javascriptEnabled: true,
                    modifyVars: {
                    "@baseColor": this.$store.state.baseColor,
                    path: "/static/UnityWeb/desktop/"
                    },
                    paths: ["/static/UnityWeb/desktop/"]
                })
                .then((data) => {
                    this.link && this.link.remove();
                    const href: string = URL.createObjectURL(new Blob([data.css]));
                    const link = document.createElement("link");
                    link.href = href;
                    link.rel = "stylesheet";
                    document.head.appendChild(link);
                    this.link = link;
                });
            }
            changeshow () {
                if (this.showSearch == -1) {
                this.showSearch = this.isRoominfo ? 1 : 2;
                } else {
                this.showSearch = -1;
                }
            }

            toDevice (item: any) {
                // console.log(item);
                if (item.guid) {
                // @ts-ignore
                document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "CameraMove", `${ item.guid }`);
                }
            }
            toRepair (item: any) {
                // 房间信息弹窗和设备信息弹窗的维修单跳转
                if (item.isactor && item.status !== 4) {
                this.$router.push({
                    path: `/repair/operation/${ item.id }/`
                });
                } else {
                this.$router.push({
                    path: `/repair/detail/${ item.id }/`
                });
                }
            }

            renderLabel (string: string, h: CreateElement): VNode {
                let name: string = "";
                switch (string) {
                case "info":
                    name = "信息";
                    break;
                case "repair":
                    name = "工单";
                    break;
                case "source":
                    name = "朔源";
                    break;
                case "monitor":
                    name = "监测";
                    break;
                case "file":
                    name = "文档";
                    break;
                case "device":
                    name = "设备";
                    break;
                case "video":
                    name = "视频";
                    break;
                default:
                    name = "";
                }
                return h("div", {}, [
                // @ts-ignore
                h("img", {
                    attrs: { src: imgMap[string] },
                    staticStyle: { display: "block", margin: "0 8px" }
                }),
                h(
                    "span",
                    { staticStyle: { display: "block", width: "36px", textAlign: "center" } },
                    name
                )
                ]);
            }

                // 改变视角
                changePres (val: string) {
                    this.unityBtnactive = val;
                    document.getElementById("frame").contentWindow.gameInstance.SendMessage("Canvas", "shitu", `${ val },${ this.isappDrawBack ? 0 : 40 }`);
                }
                getAuth () {
                    return new Promise((resolve, reject) => {
                        request("/user/self/").then(({ data }) => {
                            // console.log(data.data.permissions, "分析分配班组", data.data.permissions.indexOf("分析分配班组") > -1);
                            resolve(data.data.superuser);
                        });
                    });
                }
                getqweather () {
                    request("/qweather/").then(({ data }) => {
                        let mysrc = require("@/assets/icon/weather/qingtian.svg");
                        if (data.now.text.indexOf("雨") > -1) {
                            mysrc = require("@/assets/icon/weather/xiaoyu.svg");
                        } else if (data.now.text.indexOf("雪") > -1) {
                            mysrc = require("@/assets/icon/xue.png");
                        } else if (data.now.text.indexOf("阴") > -1) {
                            mysrc = require("@/assets/icon/weather/yintian.svg");
                        } else if (data.now.text.indexOf("多云") > -1) {
                            mysrc = require("@/assets/icon/weather/duoyun.svg");
                        }
                        this.weather.img = mysrc;
                        this.weather.temp = data.now.temp;
                    });
                }
                getUnityMsg (res) {
                    if (typeof res.data === "string" && res.data.length < 10) {
                        if (res.data == "end") {
                            setTimeout(() => {
                                this.showBtnAll = true;
                                this.isappDrawBack = false;
                                this.changePres(this.unityBtnactive);
                                this.$store.commit("unityEnd");
                            }, 3000);
                        }
                    }
                }
                getMeaus (type:any = "parent__isnull") {
                    let temp = {
                        "ordering": "sequence"
                    };
                    if (type === "parent__isnull") {
                        temp.parent__isnull = true;
                    }
                    return new Promise((resolve) => {
                        request({
                            url: "/system/rest/menu/",
                            params: temp
                        }).then(({ data }) => {
                            resolve(data);
                        });
                    });
                }
                getChildMeaus (id) {
                    return new Promise((resolve) => {
                        request({
                            url: "/system/rest/menu/",
                            params: {
                                "ordering": "sequence",
                                "parent": id
                            }
                        }).then(({ data }) => {
                            resolve(data);
                        });
                    });
                }
            mounted () {
                // 消息点击事件
                document.addEventListener("click", (e) => {
                    // console.log(this.$refs.messageIcon.contains, e.target, this.$refs.messageDom.$el.contains);
                    // 点击查看信息
                    let isSelf = false;
                    if (this.$refs.messageIcon) {
                        let isSelf = this.$refs.messageIcon.contains(e.target);
                        if (isSelf) {
                            this.showMessage = !this.showMessage;
                        }
                    }
                    if (!isSelf) {
                        if (this.$refs.messageDom) {
                            let isSelf2 = this.$refs.messageDom.$el.contains(e.target);
                            if (!isSelf2) {
                                this.showMessage = false;
                            }
                        }
                    }
                });
                // unity交互
                window.addEventListener("message", this.getUnityMsg);

                // 天气接口
                this.getqweather();
                window.Startend = () => {
                    // @ts-ignore
                    document
                        .getElementById("frame")
                        .contentWindow.gameInstance.SendMessage(
                        "Canvas",
                        "SendUrl",
                        window.location.hash
                        );
                    };
                    // 默认系统颜色
                    // this.renderCss();
                    this.time = moment(new Date()).format("YYYY年MMMDD日 dddd HH:mm:ss");
                    setInterval(() => {
                    this.time = moment(new Date()).format("YYYY年MMMDD日 dddd HH:mm:ss");
                    }, 1000);
                    this.getMessage();
                    if (this.noModule) {
                    this.visibility = 0;
                    setTimeout(() => {
                        window.end({ SendMessage: console.log });
                    });
                }
                this.firstLoad = new Promise((resolve) => {
                    window.end = () => {
                        this.visibility = 0;
                        // console.log(this.$route.fullPath);
                        resolve();
                        if (this.$route.fullPath == "/mediicalData/epidemic/") {
                        // @ts-ignore
                        document
                            .getElementById("frame")
                            .contentWindow.gameInstance.SendMessage("Canvas", "DoorControlPoint", "true");
                        }
                    };
                });
                if (this.$route.fullPath.indexOf("device") !== -1) {
                    this.isRoominfo = false;
                } else {
                    this.isRoominfo = true;
                }

                this.$store.subscribe((mutation) => {
                    const type = mutation.type;
                    //   console.log("new event", mutation);
                    if (type === "changeModule") {
                        // console.info("rest workLine");
                        this.restModule(mutation.payload.url);
                        return;
                    } else if (type === "changeBaseColor") {
                        this.renderCss();
                        return;
                    }
                    // @ts-ignore
                    this.firstLoad.then(() => {
                        // console.log("event start", mutation);
                        // @ts-ignore
                        const SendMessage = document.getElementById("frame").contentWindow.gameInstance
                        .SendMessage;
                        if (mutation.type === "changeBoolean") {
                        SendMessage("Canvas", mutation.payload.name, mutation.payload.value.toString());
                        } else if (this.$store.state.showType === "room") {
                        if (type === "changeViewByCode") {
                            // console.log("dddddddd");

                            if (mutation.payload.stop) return;
                            const id = mutation.payload.id;
                            const showCamera = this.$store.state.showCamera;
                            if (id === "") {
                            SendMessage(
                                "Canvas",
                                "ChangeFloor",
                                117 +
                                "/" +
                                showCamera +
                                "/false/" +
                                (!this.$store.state.showWalls).toString() +
                                "/false"
                            );
                            } else {
                            SendMessage(
                                "Canvas",
                                "ChangeFloor",
                                id +
                                "/" +
                                showCamera +
                                "/false/" +
                                (!this.$store.state.showWalls).toString() +
                                "/false"
                            );
                            }
                        } else if (type === "changeColorType") {
                            SendMessage("Canvas", "ChangeRoomType", mutation.payload);
                        } else if (type === "findFloorAndCamera") {
                            SendMessage(
                            "Canvas",
                            "ChangeFloor",
                            `${ mutation.payload[0] }/true/false/false/false/camera:${ mutation.payload[1] }`
                            );
                        } else if (type === "changeWall") {
                            SendMessage("Canvas", "HideWalls", (!mutation.payload).toString());
                        } else if (type === "updateCameraStatus") {
                            if (this.$store.state.floor !== "" && mutation.payload) {
                            SendMessage(
                                "Canvas",
                                "ChangeFloor",
                                this.$store.state.floorValue + "/true/false/false/false"
                            );
                            } else if (!mutation.payload) {
                            SendMessage("Canvas", "ShowCommonCamera", "false");
                            }
                        } else if (type === "selectDevice") {
                            SendMessage("Canvas", "CameraMove", mutation.payload);
                        } else if (type === "findRoom") {
                            SendMessage(
                            "Canvas",
                            "ChangeFloor",
                            mutation.payload.floor +
                                "/false/false/false/false/block:" +
                                mutation.payload.id
                            );
                        } else if (type === "fChangeFloor") {
                            SendMessage("Canvas", "fChangeFloor", mutation.payload.id);
                        } else if (typeof mutation.payload === "boolean") {
                            // ShowEviromentofThewall OpenSpacepointinf
                            SendMessage("Canvas", type, mutation.payload.toString());
                        }
                        } else {
                        if (type === "selectDevice") {
                            SendMessage("Canvas", "CameraMove", mutation.payload);
                        } else if (type === "changeSystem") {
                            SendMessage("Canvas", "SwitchSpeci", mutation.payload);
                        } else if (type === "changeFlow") {
                            SendMessage("Canvas", "Flow", mutation.payload.toString());
                        } else if (type === "changeViewByCode") {
                            this.$store.commit("changeModule", {
                            type: "device",
                            url: mutation.payload.id - 90,
                            floor: mutation.payload.id
                            });
                        }
                        }
                    });
                });
                window.getMessage = (id: string) => {
                    const event: { name: string; value: string } = JSON.parse(id);
                    if (event.name === "camera") {
                        this.$store.commit("updateLiveStream", event.value);
                    } else if (event.name === "Repair") {
                        this.$router.push(`/repair/detail/${ event.value }/`);
                    }
                };

                window.selected = (id: string) => {
                    if (!id) {
                        return;
                    }
                    if (this.$store.state.showType === "room") {
                        // 房间预览
                        this.cancelToken && this.cancelToken();
                        request({
                        url: "/model/rest/pb/guid2obj/",
                        params: {
                            guid: id
                        },
                        cancelToken: new CancelToken((cancel) => {
                            this.cancelToken = cancel;
                        })
                        }).then(({ data }) => {
                        this.attachInfo = [
                            // @ts-ignore
                            { name: "房间名", value: data.rooms[0].Name, img: imgMap["roomName"] },
                            // @ts-ignore
                            { name: "房间号", value: data.rooms[0].number, img: imgMap["roombianhao"] },
                            // @ts-ignore
                            {
                            name: "使用部门",
                            value: data.rooms[0].department__name,
                            img: imgMap["sybm"]
                            },
                            // @ts-ignore
                            {
                            name: "房间类型",
                            value: data.rooms[0].category__name,
                            img: imgMap["roomtype"]
                            },
                            // @ts-ignore
                            {
                            name: "使用面积",
                            value: data.rooms[0].GrossArea ? data.rooms[0].GrossArea + " m²" : "",
                            img: imgMap["symj"]
                            },
                            // @ts-ignore
                            {
                            name: "建筑面积",
                            value: data.rooms[0].NetArea ? data.rooms[0].NetArea + " m²" : "",
                            img: imgMap["roombianhao"]
                            }
                        ];
                        this.showAttach = true;
                        request({
                            url: `/repair/rest/projectevent/?room=${ data.rooms[0].id }&eventtype=2&pagesize=100`
                        }).then((res) => {
                            this.roomMessage.repairlist = res.data.results;
                        });
                        request({
                            url: `/device/device2/?room=${ data.rooms[0].id }&pagesize=100`
                        }).then((res) => {
                            this.roomMessage.devicelist = res.data.results;
                        });
                        request({
                            url: `/file/rest/doc2relate/?retry=5&retryDelay=500&relatetype=Room&relateid=${ data.rooms[0].id }&format=json`
                        }).then((res) => {
                            this.roomMessage.filelist = res.data;
                        });
                        });
                    } else if (this.$store.state.showType === "device") {
                        this.cancelToken && this.cancelToken();
                        this.showDevice = false;
                        request({
                        url: "/device/device/",
                        params: {
                            model__guid: id
                        },
                        cancelToken: new CancelToken((cancel) => {
                            this.cancelToken = cancel;
                        })
                        }).then(({ data }) => {
                        if (data.count === 1) {
                            const device = data.results[0];
                            const baseInfo: Array<deviceInfoTable> = [
                            {
                                name: "名称",
                                icon: "&#xe886;",
                                value: device.device_category_name + device.serialnumber
                            },
                            {
                                name: "编号",
                                icon: "&#xe731;",
                                value: device.code
                            },
                            {
                                name: "位置",
                                icon: "&#xe622;",
                                value: device.spacestr
                            },
                            {
                                name: "设备状态",
                                icon: "&#xe66f;",
                                value:
                                ((status: string): string => {
                                    if (status === "000") {
                                    return "正常";
                                    } else {
                                    let stringArray: string[] = [];
                                    if (status.charAt(0) === "1") {
                                        stringArray.push("维修中");
                                    }
                                    if (status.charAt(1) === "1") {
                                        stringArray.push("维保中");
                                    }
                                    if (status.charAt(2) === "1") {
                                        stringArray.push("有警报");
                                    }
                                    return stringArray.join();
                                    }
                                })(device.status) || "未知"
                            },
                            {
                                name: "盘点状态",
                                icon: "&#xe62d;",
                                value: ["未知", "已盘点", "待盘点", "其他"][device.pandianstatus]
                            },
                            {
                                name: "设备类型",
                                icon: "&#xe676;",
                                value: device.mepsystemname.join()
                            }
                            ];
                            const deviceId = device.id;
                            const workLine: AxiosPromise[] = [
                            request({
                                url: "/device/sensor/",
                                params: {
                                device: deviceId,
                                ordering: "-sensortype__warrantydescription"
                                }
                            }),
                            request({
                                url: "/file/rest/doc2relate/",
                                params: {
                                relatetype: "device",
                                relateid: deviceId
                                }
                            }),
                            request({
                                url: "/device/deviceconnection/",
                                params: {
                                device1_id: deviceId
                                }
                            }),
                            request({
                                url: "/device/deviceconnection/",
                                params: {
                                device2_id: deviceId
                                }
                            }),
                            request({
                                url: "/repair/rest/projectevent/",
                                params: {
                                ProjecteventElement__relatedid: deviceId
                                // ProjecteventElement__relatetype: "%E8%AE%BE%E5%A4%87"
                                }
                            })
                            ];
                            Promise.all(workLine).then(([sensor, doc, downstream, upstream, repair]) => {
                            const sensorData: Array<deviceInfoTable> = [];
                            sensor.data.results.forEach((t: any) => {
                                let temp = {
                                name: t.name,
                                value: t.lastdata,
                                range: 0,
                                status: "status" + "-" + t.laststation,
                                offset: 0,
                                class: ""
                                };
                                if (t.range11 && !isNaN(Number(t.range11))) {
                                if (t.laststation === 4) {
                                    temp.value = "离线";
                                } else {
                                    let range = [0, 100];
                                    if (t.normalrange) {
                                    let baseRange:
                                        | string[]
                                        | number[] = (t.normalrange as string)
                                        .replace(/ /g, "")
                                        .replace(new RegExp(t.unit, "g"), "")
                                        .split("-");
                                    if (baseRange.length === 1) {
                                        if (/>/.test(baseRange[0])) {
                                        const number: string = baseRange[0].replace(/>/, "");
                                        baseRange = [Number(number), Number(number) * 1.5];
                                        } else if (/</.test(baseRange[0])) {
                                        const number: string = baseRange[0].replace(/</, "");
                                        baseRange = [0, Number(number)];
                                        }
                                    }
                                    const span: number = Math.abs(
                                        Number(baseRange[0]) - Number(baseRange[1])
                                    );
                                    range = [
                                        Number(baseRange[0]) - 0.3 * span,
                                        Number(baseRange[1]) + 0.3 * span
                                    ];
                                    } else {
                                    range[1] =
                                        (t.range11 || 0) +
                                        Math.abs(t.range11 || 0 - t.range12 || 0) * 0.3;
                                    }
                                    if (t.range11 && t.range12) {
                                    temp.class = t.range11 > t.range12 ? "inside" : "outside";
                                    } else if (t.range11) {
                                    temp.class = "linear";
                                    } else {
                                    temp.class = "revLinear";
                                    }
                                    // console.log(range);
                                    temp.offset =
                                    Math.abs((t.lastdata - range[0]) / (range[0] - range[1])) * 100;
                                    if (temp.offset > 100) {
                                    temp.offset = 100;
                                    }
                                    temp.range = Number(t.range11);
                                }
                                }
                                sensorData.push(temp);
                            });
                            const docs = doc.data.map((t: any) => ({
                                name: t.document.name,
                                fileType: t.document.filetype,
                                url: t.document.fileurl
                            }));
                            this.showDevice = true;
                            this.deviceInfo = {
                                base: baseInfo,
                                sensor: sensorData,
                                file: docs,
                                moduleSrc: "",
                                downstream: downstream.data.results.map((t: any) => ({
                                name: t.device2.device_category_name + t.device2.code,
                                guid: t.device2.dbid.modelguid
                                })),
                                // @ts-ignore
                                upstream: upstream.data.results.map((t: any) => ({
                                name: t.device1.device_category_name + t.device1.code,
                                guid: t.device1.dbid.modelguid
                                })),
                                repair: repair.data.results
                            };
                            });
                        } else {
                            this.$Message.info("当前设备尚未录入");
                        }
                        });
                    } else if (this.$route.name === "requestAdd") {
                        request({
                        url: "/space/room/",
                        params: {
                            room_guid: id
                        }
                        }).then(({ data }) => {
                        const temp = data.results[0];
                        this.$store.commit("findRoom", {
                            floor: temp.floorid,
                            id,
                            path: [temp.floorfacilityid, temp.floorid, temp.id]
                        });
                        });
                    } else if (this.$route.name === "elevator") {
                        request({
                        url: "/smt/rest/camera/",
                        params: {
                            model__guid: id
                        }
                        }).then(({ data }) => {
                        const value = data.results[0];
                        if (value) {
                            this.$store.commit("updateLiveStream", [value.id, value.cameraUuid].join());
                        } else {
                            this.$store.commit("updateLiveStream", "");
                        }
                        });
                    }
                };
                // 主题色2.0   2023.07.26
                if (document.getElementById("root")) {
                    document.getElementById("root").style.setProperty("--themeColor", this.$store.state.baseColor);
                }
            }
            created () {
                // 查看用户是否登录, 前端获取不到sessionid，所以改成查看用户信息
                request("/user/self/").then(({ data }) => {
                    console.log(data.data.id, "登陆成功");
                }).catch(() => {
                    console.log("登陆失败:", window.location.href);
                    if (window.location.href.indexOf("login") < 0) {
                        this.$router.push("/login");
                    }
                });
            }
            @Watch("myroom")
            ChangeModel (newvalue: string) {
                if (!newvalue) {
                this.searchroom = [];
                return;
                }
                this.showloading = true;
                this.cancelToken && this.cancelToken();
                request({
                url: "/space/room/",
                params: {
                    pagesize: 100,
                    ordering: "floor_id",
                    search: newvalue
                },
                cancelToken: new CancelToken((cancel) => {
                    this.cancelToken = cancel;
                })
                })
                .then((res) => {
                    this.searchroom = res.data.results;
                    this.showloading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
            }

            @Watch("mydevice")
            ChangeDeviceData (newvalue: string) {
                if (!newvalue) {
                this.searchdevice = [];
                return;
                }
                this.showloading = true;
                this.cancelToken && this.cancelToken();
                request({
                url: "/device/device2/",
                params: {
                    pagesize: 100,
                    search: newvalue
                },
                cancelToken: new CancelToken((cancel) => {
                    this.cancelToken = cancel;
                })
                })
                .then((res) => {
                    this.searchdevice = res.data.results;
                    this.showloading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
            }

            @Watch("$route", { immediate: true })
            onRouteChange (
                newValue: { name: string } = { name: this.$route.name || "" },
                old: { name: string } = { name: "" }
            ) {
                // 切换unity,bimface模型
                let routeNames:any = ["home2"];
                this.showBimface = !(routeNames.indexOf(newValue.name) > -1);
                this.routerType = newValue.name;
                // unity init
                this.showBtnAll = false;
                this.isappDrawBack = true;
                if (this.$route.fullPath.indexOf("login") !== -1) {
                    // 是登录页面
                    this.isLogin = false;
                    return;
                } else {
                    this.isLogin = true;
                    // 菜单
                    this.getMeaus().then(data => {
                        this.topLevel = data;
                        // 登陆之后  超级用户管理员才可进入系统配置菜单
                        this.getAuth().then((res:boolean) => {
                            let tempShow:boolean = res;
                            this.topLevel.forEach((item, index) => {
                                if (item.name === "系统配置") {
                                    this.topLevel[index].isshow = tempShow;
                                }
                            });
                        });
                    });
                    // 菜单选中
                    this.getMeaus("all").then(data => {
                        data.forEach(val => {
                            if (val.url === this.$route.fullPath) {
                                this.secondActive = val.name;
                                let arr = val.parent.split("/system/rest/menu/");
                                let arr1 = arr.length >= 2 ? arr[1].split("/") : [""];
                                this.menuActiveId = arr1[0];
                                console.log(this.menuActiveId, val.parent);
                            }
                        });
                    });
                }
                if (this.$route.fullPath.indexOf("device") !== -1) {
                    this.isRoominfo = false;
                } else {
                    this.isRoominfo = true;
                }
                this.mydevice = "";
                this.myroom = "";
                this.showSearch = -1;
                this.myindex1 = -1;
                this.myindex2 = -1;
                this.showAttach = false;
                this.closexhStatus = false;
                this.showDevice = false;
                const oldName = old.name;
                const newName = newValue.name;
                // console.log(oldName, newName);
                if (newName === "deviceMain") {
                this.$store.commit("changeModule", {
                    type: "device",
                    url: "25",
                    floor: 115
                });
                } else if (newName === "elevator") {
                this.$store.commit("changeModule", {
                    type: "elevator",
                    url: "elevator",
                    floor: ""
                });
                } else if (this.$store.state.showType !== "room") {
                    this.$store.commit("changeModule", {
                        type: "room",
                        url: "space",
                        floor: ""
                    });
                }
                if (this.$route.fullPath == "/medicalData/epidemic/") {
                    // @ts-ignore
                    document.getElementById("frame").contentWindow.gameInstance.SendMessage("Canvas", "DoorControlPoint", "true");
                }
                if (newName === "home") {
                    this.$store.commit("changeBoolean", { name: "OpenSpacepointinf", value: true });
                    this.$store.commit("changeBoolean", {
                        name: "ShowEviromentofThewall",
                        value: true
                    });
                } else {
                    this.$store.commit("changeBoolean", {
                        name: "ShowEviromentofThewall",
                        value: false
                    });
                    this.$store.commit("changeBoolean", { name: "OpenSpacepointinf", value: false });
                }
                if (newName === "repairTable") {
                    this.$store.commit("changeBoolean", { name: "OpenRepair", value: "true" });
                    this.$store.commit("changeBoolean", { name: "OpenStation", value: "维保/false" });
                    this.$store.commit("changeBoolean", { name: "OpenStation", value: "维修/true" });
                } else if (newName === "maintenanceTable") {
                    this.$store.commit("changeBoolean", { name: "OpenRepair", value: "true" });
                    this.$store.commit("changeBoolean", { name: "OpenStation", value: "维修/false" });
                    this.$store.commit("changeBoolean", { name: "OpenStation", value: "维保/true" });
                } else if (oldName === "maintenanceTable" || oldName === "repairTable") {
                    this.$store.commit("changeBoolean", { name: "OpenRepair", value: "false" });
                }
            }

            @Watch("$store.state.isDrawBack")
            onisDrawBackchaneg (val) {
                this.isappDrawBack = val;
                this.changePres(this.unityBtnactive);
                // 调整视角居中，靠右
                // console.log("收拉数据");
            }
            private subscribe: () => void = () => void 0;
	}
